.car-card .card-content {
  font-family: "Roboto";
  font-size: 1.5em;
  line-height: 1.2em;
  font-weight: 600;
  color: #2a3b41;
}

.car-card .card-content .car-card-title {
  font-family: "Exo 2";
  font-weight: 700;
}

.car-card .card-content a {
  color: #2a3b41;
}

.car-card .car-card-price {
  font-family: "Exo 2";
  display: block;
  font-size: 1.6em;
  font-weight: 900;
  color: #1c4d1f;
  margin-top: 10px;
}

.car-card-footer {
  display: flex;
  justify-content: space-between;
  align-content: center;
  color: #1c4d1f;
}

.car-card .card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 550px !important;
}

.car-card .card-content {
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (min-width: 992px) {
  .car-card .card {
    min-height: 600px !important;
  }
}
