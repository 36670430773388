.icon-blocks {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 60px 0 ;
}

.icon-block {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  align-content: center;
  width: 100%;
  background-color: #FCFCFE;
  border: 1px solid #EAF0EF;
  border-radius: 10px;
  padding: 40px;
}

.icon-block h3 span {
  font-size: 1.5em;
  font-family: 'Exo 2';
  font-style: italic;
  margin: 0;
  line-height: 1em;
}

.icon-block .icon {
  font-size: 3.5em;
}


@media (min-width: 992px){

  .icon-blocks {
    flex-direction: row;
  }

  .icon-block {
    width: calc(100% / 3);
  }

}
