.react-datepicker-popper {
  z-index: 4;
}

.react-datepicker {
  font-family: 'Roboto', Arial, Helvetica, sans-serif !important;
  font-size: 1em;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  border: none;
}

.react-datepicker__header {
  background-color: #eaf0ef;
  border-bottom: none;
}

.react-datepicker__current-month {
  color: #2a3b41;
}

.react-datepicker__day-name {
  color: #2a3b41;
}

.react-datepicker__day,
.react-datepicker__time-list-item {
  color: #517280;
}

.react-datepicker__day--disabled {
  color: #dddddd;
}

.react-datepicker__day--selected,
.react-datepicker__time-list-item--selected {
  color: #ffffff !important;
  background-color: #f08220 !important;
}

.react-datepicker-popper {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 0.25s;
}

