.instagram-button {
  display: inline-block;
  margin-left: 20px;
}

.instagram-button svg {
  font-size: 1.9em;
  color: #5b6f78;
  margin-top: 3px;
}

@media (min-width: 992px) {
  .instagram-button {
    margin-left: 0;
  }

  .instagram-button svg {
    font-size: 1.5em;
  }
}
