.cred-card {
  margin-bottom: 20px;
}

.cred-card .card-content {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.cred-card .cred-card-btns,
.cred-card .cred-card-main {
  width: 25%;
  justify-self: flex-end;
  margin-left: auto;
  text-align: end;
}

.cred-card .cred-card-brand {
  width: 25%;
  font-weight: bold;
  font-size: small;
}

.cred-card .cred-card-number {
  width: 50%;
  text-transform: uppercase;
  text-align: center;
  font-size: small;
}

.cred-card-add-btn {
  margin-bottom: 20px;
}



.modal-cred-card-add {
  max-width: 400px;
}

.modal-cred-card-add .form-session {
  margin-bottom: 0;
}

.modal-cred-card-add .field-cardName,
.modal-cred-card-add .field-cardNumber {
  width: 100%;
}

.modal-cred-card-add .field-cardExpirationDate,
.modal-cred-card-add .field-cardSecurityCode {
  width: 50%;
}

.btn.cred-card-add-btn {
  justify-self: flex-end;
  margin-left: auto;
}

.btn.cred-card-set-main,
.btn.cred-card-delete {
  width: 30px;
  margin-left: 5px;
}

.btn.cred-card-set-main:hover {
  background-color: #1890ff;
  color: #fff;
}

.btn.cred-card-delete:hover {
  background-color: #f5222d;
  color: #fff;
}

@media (min-width: 992px) {
  .cred-card .cred-card-brand {
    font-size: large;
  }

  .cred-card .cred-card-number {
    font-size: medium;
  }
}
