.checkout {
  color: #517280;
  font-size: 1.2em;
}

.checkout h3 {
  font-size: 24px;
  line-height: 32px;
  margin: 0;
  color: #2a3b41;
}

.checkout-booking {
  margin: 40px 0;
}

.checkout-booking-item {
  margin-bottom: 20px;
}

.checkout-booking-item span {
  display: block;
}

.checkout-values-item {
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
}

.checkout-values-item-info h3 {
  margin: 0;
}

.checkout-values {
  margin-bottom: 40px;
}

.checkout-values-item-value {
  font-size: 20px;
  font-weight: bold;
  margin-top: 3px;
  color: #2a3b41;
}
.checkout-values-item-value .discount {
  text-decoration: line-through;
  color: #517280;
  font-weight: normal;
  font-style: italic;
}

.checkout-total {
  display: flex;
  justify-content: space-between;
  font-size: 32px;
  line-height: 54px;
  font-family: 'Exo 2';
  font-weight: 900;
  font-style: italic;
  color: #1c4d1f;
}

.checkout-confirm button {
  width: 100%;
  padding: 15px 0 !important;
  margin: 20px 0;
  background-color: #f08220;
  color: #fff;
  border: none;
  font-size: 18px;
  font-weight: 700;
}

@media (min-width: 992px) {
  .checkout {
    display: flex;
    align-items: stretch;
    gap: 48px;
  }


  .checkout .checkout-booking h3 {
    font-size: 1.2em;
  }

  .checkout>div {
    width: calc((100%/3) - 16px);
    margin: 0;
  }

  .checkout .checkout-booking-item:last-child,
  .checkout .checkout-values-item:last-child {
    margin-bottom: 0;
  }

  .checkout .checkout-values-item:first-child {
    margin-top: 0;
  }


  .checkout .checkout-final {
    justify-self: flex-end;
    align-self: flex-end;
  }

  .checkout .checkout-total {
    font-size: 2em;
    margin: 0;
  }


  .checkout .checkout-total span {
    line-height: 1em !important;
  }

  .checkout .checkout-confirm button {
    margin-bottom: 0;
  }
}
