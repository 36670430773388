.carousel-dot-navigators ul {
  width: 100%;
  padding: 20px 0;
  margin: 0;
  display: flex;
  justify-content: center;
  gap: 20px;
  list-style: none;
}

.carousel-dot-navigators li span {
  cursor: pointer;
  display: block;
  padding: 5px;
  background-color: #eaf0ef;
  border-radius: 10px;
}

.carousel-dot-navigators li span.current {
  background-color: #517280;
}

.carousel-dot-navigators.orange li span {
  background-color: #fff;
}

.carousel-dot-navigators.orange li span.current {
  background-color: #f08220;
}
