.car-group-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.car-group-more {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 0 64px;
}


@media (min-width:992px) {
  .car-group-list {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
  }

  .car-group-list .car-group {
    width: calc((100% / 2) - 16px);
  }
}

@media (min-width:1200px) {

  .car-group-list .car-group {
    width: calc((100% / 3) - 16px);
  }
}
