.contact-form button {
  width: 100%;
  padding: 15px;
  background-color: #1c4d1f;
}

@media (min-width:992px) {

  .contact-form form {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
  }

  .contact-form .form-session {
    width: calc((100% / 2) - 16px);
    justify-content: start;
    margin-bottom: 0;
  }

  .contact-form .form-session.terms {
    width: 70%;
  }

  .contact-form .form-session.button {
    width: 25%;
  }

}
