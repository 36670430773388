.field-checkbox {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  color: #517280;
  position: relative;
  padding: 3px;
}

.field-checkbox input {
  position: absolute;
  left: -15px;
  width: auto;
  visibility: hidden;
}

.checkbox-styled {
  background-color: #eaf0ef;
  padding: 2px 3px;
  line-height: 1em;
  border-radius: 3px;
  font-size: 1em;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
}

.checkbox-styled .checkbox-checked-icon {
  visibility: hidden;
}

.checkbox-styled.checkbox-checked .checkbox-checked-icon {
  visibility: visible;
}

.checkbox-label {
  margin-left: 10px;
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

.checkbox-label.labelLink {
  cursor: auto;
}

.field-checkbox.disabled .checkbox-styled,
.field-checkbox.disabled .checkbox-label {
  color: #bbb
}
