.carousel-card-images {
  position: relative;
  border-bottom: 1px solid #eaf0ef;
  margin: -20px -20px 20px -20px;
}

.carousel-card-images .carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 300px;
  flex-shrink: 0;
  scroll-snap-align: start;
  background-color: #eaf0ef;
}

.carousel-card-images .carousel .null-item {
  display: none;
}

.carousel-card-images .carousel-item img {
  flex-shrink: 0;
  width: 100%;
}

.carousel-card-images .carousel-dot-navigators {
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.carousel-card-images .carousel-dot-navigators span {
  background-color: #517280;
}

.carousel-card-images .carousel-dot-navigators span.current {
  background-color: #f08220;
}
