.carousel-page-header {
  position: relative;
  margin: 0;
  border-bottom: 1px solid #eaf0ef;
}

.carousel-page-header .carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: calc(100%/1);
  flex-shrink: 0;
  scroll-snap-align: start;
  background-color: #eaf0f0;
}

.carousel-page-header .carousel .null-item {
  display: none;
  background-color: transparent;
}

.carousel-page-header .carousel-item .image-wrapper {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-page-header .carousel-item img {
  flex-shrink: 0;
  width: 100%;
  height: auto;
}

.carousel-page-header {
  margin: 20px 0 40px 0;
  border-top: 1px solid #eaf0ef;
}

.carousel-page-header .carousel-item {
  height: 400px;
}


@media (min-width: 992px) {

  .carousel-page-header {
    border: none;
  }

  .carousel-page-header .carousel {
    gap: 4px;
  }

  .carousel-page-header .carousel-item {
    border: 1px solid #fff;
    width: calc(960px / 2);
  }

  .carousel-page-header .carousel .null-item {
    display: block;
    border: none !important;
    width: calc((100vw - 960px)/2);
  }

  .carousel-page-header .carousel-item .image-wrapper {
    height: auto;
  }

  .carousel-page-header .carousel-item img {
    width: 100%;
  }
}

@media (min-width: 1200px) {

  .carousel-page-header .carousel .null-item {
    width: calc((100vw - 1140px)/2);
  }

  .carousel-page-header .carousel-item {
    width: calc(1140px / 2);
  }
}

@media (min-width: 1400px) {

  .carousel-page-header .carousel .null-item {
    width: calc((100vw - 1320px)/2);
  }

  .carousel-page-header .carousel-item {
    width: calc(1320px / 2);
  }

}
