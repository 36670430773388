.main-menu-mobile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 0;
  left: 0;
  overflow-y: scroll;
  width: 100vw;
  height: 100vh;
  background: #fff;
  position: absolute;
  padding: 100px 40px 0;
  z-index: 99999;
}

.main-menu-mobile-close {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1.8em;
  padding: 30px 30px 10px 10px;
  background: transparent;
  border: none;
  color: #517280;
}

ul.main-nav-mobile {
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.main-nav-mobile li {
  padding: 5px 0;
  width: 100%;
  display: block;
}

ul.main-nav-mobile.user {
  margin-top: 40px;
}

ul.main-nav-mobile li a{
  color: #2a3b41;
  font-family: "Exo 2";
  font-size: 1.7em;
  font-weight: 700;
}

.main-menu-mobile-footer {
  padding: 40px 0;
  color: #517280;
  font-size: 1.2em;
}

.main-menu-mobile-footer .phone{
  width: 100%;
  margin-bottom: 10px;
  font-size: 2.05em;
  font-weight: 400;
  font-family: "Exo 2";
  color: #f08220;
}

.main-menu-mobile-footer .hour{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.main-menu-mobile-footer .links{
  list-style: none;
  padding: 20px 0;
  margin: 50px 0 0 0;
  border-top: 1px solid #517280;
}

.main-menu-mobile-footer .links li{
  width: 100%;
  padding: 5px 0;
}

.main-menu-mobile-footer .links li a{
  color: #517280;
  font-weight: 700;
}


@media (min-width:992px) {
  .main-menu-mobile {
    display: none;
  }
}
