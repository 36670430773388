.client-info-bar {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 15px 10px 10px;
  background-color: #edf0f1;
  border-radius: 0 0 5px 5px;
  border: 1px solid #eee;
  color: #517280;
  font-size: 0.9em;
}

.client-info-bar span {
  margin: 0 10px;
  cursor: pointer;
}

.fixed-top-bar.transparent .client-info-bar {
  box-shadow: 0 3px 5px rgba(0,0,0,0.1);
}

@media (min-width: 992px) {
  .client-info-bar {
    font-size: 1em;
  }
}
