@media (min-width:992px) {
  .modal-booking-form {
    width: 56vw;
  }

  .modal-booking-form .modal-content {
    padding: 0;
  }

  .modal-booking-form .booking-form form {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 24px;
  }

  .modal-booking-form .booking-form .form-session h3 {
    width: 100%;
  }

  .modal-booking-form .booking-form .field {
    width: 50%;
  }

  .modal-booking-form .booking-form .field-pickupLocation,
  .modal-booking-form .booking-form .field-returnLocation {
    width: 100%;
  }

  .modal-booking-form .booking-form .form-session {
    width: calc((100% / 2) - 16px);
  }

  .modal-booking-form .booking-form .form-session .form-btn {
    width: 100%;
  }
}
