.color-primary {
  color: #1c4d1f;
}

.color-secondary {
  color: #f08220;
}

.color-gray1 {
  color: #517280;
}

.color-gray2 {
  color: #77919c;
}

.color-neutral {
  color: #1890ff;
}

.color-success {
  color: #52c41a;
}

.color-warning {
  color: #fadb14;
}

.color-danger {
  color: #a61d24;
}



.bg-primary {
  background-color: #1c4d1f;
}

.bg-gray1 {
  background-color: #517280;
}

.bg-gray2 {
  background-color: #77919c;
}

.bg-neutral {
  background-color: #1890ff;
}

.bg-success {
  background-color: #52c41a;
}

.bg-warning {
  background-color: #fadb14;
}

.bg-danger {
  background-color: #f5222d;
  color: #fff;
}

.bgt-neutral {
  background-color: rgba(24, 144, 255, 0.2);
  border-color: rgba(24, 144, 255, 0.5);
}

.bgt-success {
  background-color: rgba(82, 196, 26, 0.2);
  border-color: rgba(82, 196, 26, 0.5);
}

.bgt-warning {
  background-color: rgba(250, 219, 20, 0.2);
  border-color: rgba(250, 219, 20, 0.5);
}

.bgt-danger {
  background-color: rgba(245, 34, 45, 0.2);
  border-color: rgba(245, 34, 45, 0.5)
}