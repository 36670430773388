@media (min-width: 992px) {
  .booking-done .success-page-buttons button:nth-child(1),
  .booking-done .success-page-buttons button:nth-child(2) {
    width: calc(100% / 2 - 16px);
  }

  .booking-done .success-page-buttons button:last-child {
    width: 100%;
  }
}
