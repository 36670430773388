#aboutus-page,
.aboutus-page div {
  scroll-margin: 100px;
}

.aboutus-content {
  margin-bottom: 40px;
}

.aboutus-map {
  margin-bottom: 40px;
}

.aboutus-map h2 {
  font-family: 'Exo 2';
  font-style: italic;
  font-weight: 500;
  font-size: 2.5em;
}

.aboutus-img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  overflow: hidden;
  border-radius: 10px;
}

.aboutus-img img {
  width: 100%;
  object-fit: cover;
}

@media (min-width:992px) {

  .aboutus-page .title-page {
    text-align: center;
  }

  .aboutus-content {
    max-width: 600px;
    margin: 0 auto 100px auto;
  }

  .aboutus-content-2 {
    max-width: 800px;
    margin: 0 auto 100px auto;
  }

  .aboutus-map {
    max-width: 1024px;
    margin: 0 auto 60px auto;
  }

  .aboutus-map h2 {
    max-width: 600px;
    margin: 20px auto;
  }
}
