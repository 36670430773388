.booking-status {
  display: flex;
  align-items: center;
  font-size: 0.9em;
  text-transform: uppercase;
  white-space: wrap;
}

.booking-status .icon {
  border-width: 2px;
  border-style: solid;
  border-radius: 100px;
  display: block;
  margin-right: 7px;
  padding: 0 8px;
  font-size: 1em;
  font-weight: 800;
}

.booking-status.analise_cliente_andamento,
.booking-status.aguardando_analise_manual,
.booking-status.devolucao_veiculo_em_atraso,
.booking-status.pagamento_em_processamento {
  color: #f5ba2b;
}

.booking-status.analise_cliente_andamento .icon,
.booking-status.aguardando_analise_manual .icon,
.booking-status.devolucao_veiculo_em_atraso .icon
.booking-status.pagamento_em_processamento .icon {
  border-color: #f5ba2b;
}

.booking-status.aguardando_retirada,
.booking-status.finalizada,
.booking-status.em_andamento {
  color: #52c422;
}

.booking-status.aguardando_retirada .icon,
.booking-status.finalizada .icon,
.booking-status.em_andamento .icon {
  border-color: #52c422;
}

.booking-status.pagamento_cancelado,
.booking-status.expirada,
.booking-status.cancelada,
.booking-status.cliente_reprovado,
.booking-status.pagamento_nao_autorizado {
  color: #fd4d4f;
}

.booking-status.pagamento_cancelado .icon,
.booking-status.expirada .icon,
.booking-status.cancelada .icon,
.booking-status.cliente_reprovado .icon,
.booking-status.pagamento_nao_autorizado .icon {
  border-color: #fd4d4f;
}
