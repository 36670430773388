.btn {
  font-family: 'Exo 2';
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.btn.btn-full {
  width: 100%;
}

.btn.btn-sm-x {
  padding: 3px 7px !important;
  font-size: 18px;
}

.btn.btn-sm {
  padding: 5px 15px !important;
  font-size: 18px;
}

.btn.btn-md {
  padding: 10px 20px !important;
  font-size: 18px;
}

.btn.btn-bg {
  padding: 15px 30px !important;
  font-size: 20px;
  border-radius: 10px;
}

.btn.btn-orange {
  background-color: #f08220;
  color: #fff;
}

.btn.btn-transparent {
  background-color: transparent;
  border: 2px solid #1c4d1f;
  color: #1c4d1f;
}

.btn.btn-link {
  background-color: transparent;
  border: none;
  color: #517280;
  text-decoration: underline;
  padding: 0 !important;
}

.btn:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
