.features-tags .tags {
  margin-bottom: 10px;
}

.features-tags .tag {
  margin: 0 5px 5px 0;
}

@media (min-width: 992px) {
  .features-tags .tag {
    margin: 0 5px 5px 0;
  }
}
