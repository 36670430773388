.main-footer-content .footer-menu {
  list-style: none;
  padding: 0;
  margin: 0 0 40px 0;
  font-family: "Exo 2";
}

.main-footer-content .footer-menu li{
  padding: 5px 0;
}

.main-footer-content .footer-menu li a{
  color: #1c4d1f;
  font-size: 1.3em;
  font-weight: 600;
}
