.conteudo-list-list {
  margin: 0;
}

.conteudo-list-list .carousel-header {
  padding-bottom: 15px;
}

.conteudo-list-list a {
  font-size: 1.3em;
  color: #1c4d1f;
}

.conteudo-list-list .carousel-item {
  width: 85%;
  flex-shrink: 0;
  scroll-snap-align: start;
  padding: 15px;
}

.conteudo-list-list .carousel-item .card {
  height: 100%;
  min-height: auto !important;
}

.conteudo-list-list .card .card-content {
  min-height: 120px !important;
}

@media (min-width:992px) {

  .conteudo-list-list {
    background-color: #eaf0ef;
    margin: 40px 0;
    padding: 40px 0;
  }

  .conteudo-list-list .carousel-item {
    width: calc(100%/4);
  }

}

@media (max-width:576px) {
  .conteudo-list-list > .container {
    padding: 0;
  }
}
