.rent-info {
  margin-bottom: 40px;
}

.rent-info-item {
  border-bottom: 1px solid #ddd;
  padding-bottom: 30px;
  margin-bottom: 20px;
}

.rent-info-item:last-child {
  border:none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.rent-info-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.rent-info-header h3 {
  font-family: 'Exo 2';
  font-style: italic;
  color: #1c4d1f;
  font-size: 30px;
  line-height: 38px;
  margin: 0 0 20px 0;
}
