.booking-drivers {
  margin: 30px 0;
}

.driver-text {
  width: 100%;
  margin: 40px 0 20px 0;
}

.show-booking-drivers-form {
  margin: 0 0 30px 0;
}

@media (min-width: 992px) {
  .show-booking-drivers-form {
    margin-top: 30px;
  }
}
