.booking-form-alert {
  width: 100%;
  background-color: rgba(255,255,255,0.2);
  color: white;
  font-size: 0.85em;
  border-radius: 10px;
  padding: 10px;
  margin: 0 0 20px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

@media (min-width:992px) {
  .booking-form-alert {
    font-size: 1em;
  }
}
