.card {
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #eaf0ef;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.card-img {
  position: relative;
  width: 100%;
  min-height: 200px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  background-color: #eaf0ef;
}

.card-link {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.card-img img {
  width: 100%;
  object-fit: cover;
}

.card-content {
  padding: 20px;
}

.card-footer {
  border-top: 1px solid #eaf0ef;
  padding: 15px 20px;
}
