#locacao {
  scroll-margin-top: 100px;
}

.booking-form {
  position: relative;
  width: 100%;

  margin-bottom: 40px;
  padding: 20px;
  border-radius: 10px;
  background-color: #1c4d1f;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
}

.booking-form input:hover,
.booking-form textarea:hover,
.booking-form select:hover,
.booking-form .select-styled:hover,
.booking-form .checkbox-styled:hover {
  border-color: #eaf0ef;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
}

.booking-form input:focus,
.booking-form textarea:focus,
.booking-form select:focus,
.booking-form .select-styled:focus,
.booking-form .checkbox-styled:focus {
  border-color: #eaf0ef;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
}

.booking-form .select-search input,
.booking-form .select-search input {
  box-shadow: none !important;
}

.booking-form .form-session {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.booking-form .field {
  width: 50%;
}

.booking-form .field-pickupLocation,
.booking-form .field-returnLocation {
  width: 100%;
}

.booking-form .field-hasReturnLocation .checkbox-label {
  font-weight: bold;
  font-size: 1.2em;
}


.booking-form-title {
  font-size: 24px;
  font-family: 'Exo 2';
  font-style: italic;
  color: #f5ba2b;
  padding-bottom: 10px;
  margin: 0 0 30px 0;
  border-bottom: 1px solid #f5ba2b;
  text-transform: uppercase;
}

.booking-form-subtitle {
  width: 100%;
  margin: 0 0 5px 0;
  text-transform: uppercase;
  color: #fff;
}

.modal .booking-form {
  margin: 0;
}

.booking-form .form-btn {
  padding: 12px 15px !important;
  font-size: 18px;
  width: 100%;
}

.form-session-has-return-location .field {
  width: 100% !important;
}

.form-session-has-return-location span {
  color: #fff;
}

.booking-form .field .required-indicator {
  display: none;
}

.booking-form .react-datepicker__time-list-item--disabled {
  display: none;
}


@media (min-width:992px) {
  .booking-form {
    position: relative;
  }

  .booking-form form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .booking-form .form-session {
    width: 100%;
  }

  .booking-form .field {
    width: 25%;
  }

  .booking-form .field-pickupLocation,
  .booking-form .field-returnLocation {
    width: 50%;
  }

  .booking-form .form-btn {
    width: auto;
    align-self: flex-end;
    justify-self: end;
    margin-left: auto;
  }
}

@media (min-width:1200px) {

  .booking-form form {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 48px;
  }

  .booking-form.has-return-location form {
    align-items: flex-start;
    padding-bottom: 0;
  }

  .booking-form .form-session {
    margin-bottom: 0;
    width: 55%;
  }

  .booking-form.has-return-location .form-session {
    width: 48%
  }

  .booking-form .form-session-return {
    justify-content: flex-end;
    width: 45%;
    padding-left: 10px;
  }

  .booking-form.has-return-location .form-session-return {
    width: 50%;
  }

  .booking-form .form-session-return .field {
    width: 37%;
  }


  .booking-form.has-return-location .form-session-return .field {
    width: 25%;
  }

  .booking-form.has-return-location .form-session-return .field-returnLocation {
    width: 50%;
  }

  .booking-form.has-return-location .form-session h3 {
    width: 100%;
    padding-left: 5px;
  }

  .form-session-has-return-location {
    width: 50%;
    position: absolute;
    bottom: 16px;
    padding: 24px 0 0 0;
  }

}

@media (min-width: 1400px) {

  .booking-form .booking-form-subtitle {
    width: auto;
    font-size: 1em;
    margin-right: 10px;
  }

  .booking-form.has-return-location .form-session h3 {
    width: 100%;
  }

  .booking-form .field {
    width: 21%;
  }

  .booking-form .form-session-return .field {
    width: 30%;
  }

  .booking-form.has-return-location .field {
    width: 25%;
  }

  .booking-form .field-pickupLocation,
  .booking-form .field-returnLocation {
    width: 43%;
  }

  .booking-form.has-return-location .field-pickupLocation,
  .booking-form.has-return-location .field-returnLocation {
    width: 50%;
  }
}
