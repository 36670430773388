.sc-image-box {
  display: inline-block;
  padding: 0;
  margin: 40px 0;
  position: relative;
}

.sc-image-box img {
  padding: 0;
  margin: 0;
}

.sc-image-box .sc-image-box-subtitle {
  position: absolute;
  bottom: 5px;
  left: 0;
  width: 100%;
  border-radius: 0 0 10px 10px;
  padding: 25px;
  background-color: rgba(28,77,31,0.9);
  text-shadow: 0 3px 5px rgba(0,0,0,0.5);
  color: white;
  text-align: center;
}
