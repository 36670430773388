.car-features {
  list-style: none;
  margin: 40px 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 5%
}

.car-features li {
  width: 45%;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #eaf0ef;
}

.car-features li svg {
  margin-right: 10px;
}

@media (min-width:992px) {
  /* .car-features li {
    width: 45%;
  } */
}
