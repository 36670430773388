.blog-post-card .card-img {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  overflow: hidden;
}

.blog-post-card .card-img img {
  height: 100%;
}

.blog-post-card .card-content {
  font-family: 'Exo 2';
  font-weight: 700;
  font-size: 1.2em;
}
