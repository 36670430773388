.dialog {
  position: relative;
  margin-bottom: 24px;
}

.dialog .dialog-content {
  padding: 10px 15px;
  padding-right: 35px;
  text-align: left;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
}

.dialog .icon-close {
  position: absolute;
  top: 10px;
  right: 15px;
  size: 1em;
  opacity: 0.5;
}

.dialog .icon-loading {
  margin-right: 5px;
}
