.booking-update-edit-form .react-datepicker__time-list-item--disabled {
  display: none;
}

.booking-update-edit-return {
  margin-top: 10px;
}

.booking-update-edit {
  margin-top: 40px;
}

@media (min-width:992px) {

  .booking-update-edit-form {
    max-width: 500px;
  }

}
