.cookie-bar {
  width: 100%;
  padding: 20px;
  position: fixed;
  bottom: 0;
  background-color: #517280;
  color: #fff;
  z-index: 999999;
}

.cookie-bar .container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.cookie-bar span {
  display: block;
  text-align: center;
  margin-bottom: 20px;
}

.cookie-bar button {
  justify-self: center;
}

@media(min-width:992px) {

  .cookie-bar .container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  .cookie-bar span {
    margin-right: 20px;
    margin-bottom: 0;
  }
}
