.carousel-seminovos-normal {
  margin: 100px 0;
}

.carousel-seminovos-normal .carousel-header {
  padding-bottom: 15px;
}

.carousel-seminovos-normal .carousel-item {
  width: 85%;
  flex-shrink: 0;
  scroll-snap-align: start;
  padding: 15px;
}

.carousel-seminovos-normal .carousel-item .car-card {
  height: 100%;
}

.carousel-seminovos-normal .carousel-item .car-card .card {
  height: 100%;
  min-height: auto !important;
}

.carousel-seminovos-normal .car-card .card-content {
  min-height: 150px !important;
}

@media (min-width:992px){
  .carousel-seminovos-normal {
    background-color: #eaf0ef;
    margin: 40px 0;
    padding: 40px 0;
  }

  .carousel-seminovos-normal .carousel-item {
    width: calc(100%/4);
  }
}

@media (max-width:576px) {
  .carousel-seminovos-normal > .container {
    padding: 0;
  }
}
