.payment-calcao {
  padding-top: 40px;
  margin-top: 40px;
  border-top: 1px solid #ddd;
}

.payment-calcao p {
  font-size: 18px;
}

.payment-calcao b {
  font-size: 24px;
}

@media (min-width:992px) {
  .payment-content {
    padding: 24px;
    border: 1px solid #edf0f1;
    border-radius: 10px;
  }
}
