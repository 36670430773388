.service-page-template .title-page {
  text-align: center;
}

.service-page-template .box-content {
  text-align: justify;
  margin: 0 auto;
}

.service-page-template .box-content > p {
  font-size: 16px;
}

.service-page-template .box-content > p,
.service-page-template .box-content > h1,
.service-page-template .box-content > h2,
.service-page-template .box-content > h3,
.service-page-template .box-content > h4,
.service-page-template .box-content > ul
{
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
}


