.cred-card-icons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  padding: 5px;
  margin: 10px 0;
  gap: 10px;
}

.cred-card-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 40px;
  padding: 10px 10px;
  border: 1px solid #eaf0ef;
  border-radius: 5px;
}

.cred-card-icon.selected {
  border: 1px solid #f08220;
}
