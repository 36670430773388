.additional {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}

.additional .info {
  width: 70%;
  font-size: 0.8em;
  color: #517280;
}

.additional .info h3 {
  margin: 0;
  font-size: 20px;
  line-height: 28px;
  color: #2a3b41
}

.additional .values {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.additional .values .price {
  font-size: 20px;
  line-height: 28px;
  font-weight: bold;
  text-align: right;
  margin-bottom: 20px;
}

.additional .values .quantity {
  text-align: right;
}

.additional .values .field {
  max-width: 100px;
}

.additional .values .price span {
  font-weight: normal;
  font-size: 14px;
}

.additional .values input {
  width: 100%;
  font-size: 1em !important;
  background-color: #fff;
  border: 1px solid #eaf0ef;
}

@media (min-width:1200px) {
  .additional {
    flex-direction: row;
  }

  .additional .values {
    width: 30%;
    flex-direction: row;
    justify-content: space-between;
  }

  .additional .info {
    width: 50%;
  }

  .additional .values {
    width: 40%;
  }
}
