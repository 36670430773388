.page-banner-top-image {}

.page-banner-top-image {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-banner-top-image-desktop {
  display: none;
}

.page-banner-top-image-mobile {
  width: 100%;
}

@media (min-width:768px){
  .page-banner-top-image-desktop {
    display: block;
    max-width: 100%;
  }

  .page-banner-top-image-mobile {
    display: none;
  }
}
