.main-footer-last-menu {
  display: none;
  list-style: none;
  padding: 0;
  margin: 0 0 40px 0;
  font-family: 'Exo 2';
  color: #1c4d1f;
  font-size: 1.3em;
  font-weight: 600;
}

.main-footer-last-menu li {
  padding: 5px 0;
}

.main-footer-last-menu a {
  color: #1c4d1f;
}

.main-footer-content .links{
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 1.1em;
  font-weight: 500;
  color: #77919c;
}

.main-footer-content .links li{
  padding: 5px 0;
}

.main-footer-content .links li a{
  color: #77919c;
}


@media (min-width: 992px) {
  .main-footer-last-menu {
    display: block;
  }
}
