.cta-home {
  overflow: hidden;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin: 60px 0;
}

.cta-home-desktop {
  display: none;
  height: 200px;
}

.cta-home-mobile {
  display: flex;
}

.cta-home img {
  width: 100%;
}

.home-page .booking-form {
  margin-top: -65px;
}


@media (min-width:992px) {
  .cta-home-desktop {
    display: flex;
  }

  .cta-home-mobile {
    display: none;
    height: auto;
  }

}
