.pj-form .checkbox-group-fields {
  display: flex;
  flex-direction: column;
}

.pj-form .checkbox-group-fields label {
  width: 100%;
  padding: 10px;
  border: 1px solid #eee
}
