.driver-card {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
}

.driver-card button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: small !important;
}

@media (min-width: 992px) {
  .driver-card {
    flex-direction: row;
    align-items: center;
  }

  .driver-card button {
    position: relative;
    top: 0;
    right: 0;
  }
}
