.cancel-booking-modal {
  max-width: 400px;
  min-height: 200px;
}

.cancel-booking-modal .modal-content {
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  font-size: medium;
}

.cancel-booking-modal .row-button .btn {
  margin: 0 5px;
}
