.sc-styled-lyst {
  display: block;
  list-style: none;
  margin: 40px 0;
  padding: 0;
}

.sc-styled-lyst li {
  margin: 10px 0;
  padding: 15px;
  background-color: #EAF0EF;
  border-radius: 10px;
}

.sc-styled-lyst li .svg-inline--fa {
  margin-right: 20px;
}
