@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  box-sizing: border-box;
  line-height: 1.5em;
}

html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #2a3b41;
}

a {
  text-decoration: none;
}

h2 {
  font-size: 30px;
  line-height: 38px;
  color: #1c4d1f
}

.template-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.container {
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
}

.main-content {
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  min-height: 500px;
  padding: 0 0 120px 0;
}

.row {
  justify-content: space-between;
  display: flex;
  gap: 24px;
}


@media (min-width:576px) {
  .container {
    max-width: 540px;
  }
}

/* @media (min-width:768px) {
  .container {
    max-width: 720px;
  }
} */

@media (min-width:992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width:1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width:1400px) {
  .container {
    max-width: 1320px;
  }
}