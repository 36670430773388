.whatsapp-button {
  display: flex;
  align-items: center;
  padding: 3px 10px;
  border: solid 1px #57c629;
  border-radius: 100px;
  font-weight: 600;
  color: #57c629;
}

.whatsapp-button a {
  color: #57c629;
  display: inline-block;
}

.whatsapp-button span {
  display: inline-block;
  margin-top: -3px;
}

.whatsapp-button svg {
  font-size: 1em;
  margin-right: 10px;
}

.whatsapplink svg {
  font-size: 1.1em;
}
