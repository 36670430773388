.success-page-text {
  margin: 40px 0;
  text-align: center;
}

.success-page-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
}

.success-page-buttons .btn {
  margin-bottom: 20px;
  width: 300px;
}

@media (min-width: 992px) {

  .success-page {
    max-width: 550px;
    margin: 100px auto ;
    text-align: center;
  }

  .success-page-buttons {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 24px;
  }

  

}
