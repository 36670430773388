.dashboard-template-page {
  display: flex;
  gap: 24px;
  margin-bottom: 60px;
}

.dashboard-content {
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media (min-width: 992px) {
  .dashboard-content {
    width: 76%;
  }

  .dashboard-content-container-promo {
    display: none;
  }
}
