.booking-page .booking-code {
  display: inline-block;
  color: #517280;
  font-weight: 500;
  font-size: 1.3em;
}

.booking-head-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.booking-create-date {
  color:#77919c;
}

.booking-head-info.row {
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
}
