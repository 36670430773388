.login-background {
  width: 100%;
}

.login-box {
  width: 100%;
  padding-top: 30px;
}

.login-box .field {
  width: 100%;
}

.login-lost-password {
  text-align: right;
}

.login-lost-password a {
  color: #77919c;
}

.login-lost-password a:hover {
  text-decoration: underline;
}

.login-new-account {
  display: block;
  text-align: center;
  margin: 10px;
  font-size: 1em;
}

.login-new-account a {
  color: #2a3b41;
  text-decoration: underline;
  font-weight: 800;
  margin-left: 10px;
}

.login-box .logged-user-actions .btn {
  margin-bottom: 10px;
}


.login-background {
  background-size: 0%;
  background-position: 0%;
}


@media (min-width:992px) {

  .login-background {
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
  }

  .login-background .container {
    display: flex;
    justify-content: flex-end;
  }

  .login-box {
    width: 38%;
    padding: 48px;
    background: #fff;
    border-radius: 10px;
    margin: 100px 64px;
  }

}
