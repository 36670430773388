.modal-contact-form {
  margin-top: 10vh;
}

.modal-contact-form .field {
  width: 100%;
}

.modal-contact-form h2 {
  font-family: 'Exo 2';
  font-style: italic;
  margin: 0 0 16px 0;
}

.modal-contact-form .contact-info .whatsapp-button {
  margin: 10px 0
}


@media (min-width:992px) {

  .modal-contact-form {
    width: 50vw;
    padding: 36px;
    margin-top: 20vh;
  }

  .modal-contact-form .contact-info {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-bottom: 24px;
  }

  .contact-form .field-text {
    align-self: stretch;
  }

  .modal-contact-form .contact-info .contact-phone {
    font-size: 1.3rem;
    white-space: nowrap;
  }

  .modal-contact-form .contact-info .whatsapp-button {
    margin: 0
  }

  .contact-form .field-text textarea {
    height: 100%;
  }

}
