.home-promo {
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 75vh;
  background-color: #333;
  overflow: hidden;
}

.promo-mobile {
  display: flex;
}

.promo-desktop {
  display: none;
}

.carousel-home-top .carousel {
  position: relative;
}

.carousel-home-top .carousel-itens {
  background-color: #eaf0ef;
  width: 100%;
  display: flex;
}

.carousel-home-top .carousel-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  flex-shrink: 0;
}

.carousel-home-top .carousel-item img {
  max-width: 100%;
}

.carousel-home-top .carousel-dot-navigators {
  display: block;
  position: absolute;
  bottom: 70px;
  width: 100%;
}

.carousel-home-top .carousel-dot-navigators .current {
  background-color: #f08220;
}

.carousel-home-top .carousel-arrows-navigators {
  display: none;
}

.carousel-home-top .carousel-arrows-navigators button {
  position: absolute;
  top: 42%;
  font-size: 3.2em;
  color: #fff;
}

.carousel-home-top .carousel-arrows-navigators button.disabled {
  opacity: 0.3;
}

.carousel-home-top .carousel-arrows-navigators .carousel-scroll-prev {
  left: 20px;
}

.carousel-home-top .carousel-arrows-navigators .carousel-scroll-next {
  right: 20px;
}

.carousel-home-top .carousel-item img.desktop {
  display: none;
}

.carousel-home-top .carousel-item img.mobile {
  display: block;
}

@media (min-width:768px) {

  .carousel-home-top .carousel-dot-navigators {
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 80px;
  }

  .carousel-home-top .carousel-dot-navigators ul {
    background-color: rgba(255,255,255, 0.4);
    margin: 0 auto;
    width: auto !important;
    padding: 7px 12px;
    border-radius: 100px;
  }

  .carousel-home-top .carousel-dot-navigators ul li span {
    padding: 6px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.5);
  }

  .carousel-home-top .carousel-arrows-navigators {
    display: block;
  }


  .carousel-home-top .carousel-item img.desktop {
    display: block;
  }

  .carousel-home-top .carousel-item img.mobile {
    display: none;
  }
}

@media (min-width: 1400px) {

  .carousel-home-top .carousel-arrows-navigators .carousel-scroll-prev {
    left: 40px;
  }

  .carousel-home-top .carousel-arrows-navigators .carousel-scroll-next {
    right: 40px;
  }

}

@media (min-width: 1800px) {

  .carousel-home-top .carousel-arrows-navigators .carousel-scroll-prev {
    left: 20%;
  }

  .carousel-home-top .carousel-arrows-navigators .carousel-scroll-next {
    right: 20%;
  }

}
