.blog-post-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
}

.blog-post-list .card {
  width: 100%;
}

.blog-post-list .card .card-img {
  height: 150px;
}

.blog-post-list .card .card-content {
  font-family: 'Exo 2';
  font-weight: 700;
  font-size: 1.2em;
}

@media (min-width: 992px) {

  .blog-post-list .card {
    width: 30%;
  }
}
