.carousel {
  width: 100%;
}

.carousel-itens {
  width: 100%;
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  scroll-snap-align: start;
  cursor: grab;
}

.carousel-itens::-webkit-scrollbar {
  display: none;
}

.carousel-itens.active {
  cursor: grabbing;
}

/* Hide scrollbar for IE, Edge and Firefox */
.carousel-itens {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.carousel-header .full-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.carousel-header h2 {
  font-size: 2.5em;
  font-family: "Exo 2";
  font-style: italic;
  font-weight: 900;
  margin: 0;
}

.carousel-header a {
  font-size: 1.3em;
  color: #1c4d1f;
}

.carrousel-header-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.carrousel-header-buttons .carousel-arrows-navigators {
  display: none;
}

.carrousel-header-showmore {
  margin-left: 20px;
}

.carousel-scroll-prev,
.carousel-scroll-next {
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 1.7em;
  color: #517280;
  opacity: 0.5;
}

.carousel-scroll-prev:hover,
.carousel-scroll-next:hover {
  opacity: 1;
}

/* .carousel-scroll-prev.disabled,
.carousel-scroll-next.disabled {
  color: #eaf0ef
} */

.carousel-gradient-box{
  position: relative;
  width: 100%;
  height: auto;
}

.carousel-gradient {
  display: none;
  height: 100%;
  position: absolute;
  top: 0;
  background: #fff;
  z-index: 1;
  /* fallback for old browsers */
}

.carousel-gradient-right {
  right: 0;
  width: 300px;
  background: linear-gradient(90deg,
    rgba(255,255,255,0) 0%,
    rgba(255,255,255,0.25) 25%,
    rgba(255,255,255,0.5) 50%,
    rgba(255,255,255,1) 100%
  );
}

.carousel-gradient-left {
  left: -150px;
  width: 300px;
  background: linear-gradient(267deg,
    rgba(255,255,255,0) 0%,
    rgba(255,255,255,0.25) 25%,
    rgba(255,255,255,0.5) 50%,
    rgba(255,255,255,1) 100%
  );
}

.carousel-gradient-left.has-header,
.carousel-gradient-right.has-header {
  margin: 30px 0;
}

@media (min-width: 992px) {

  .carousel-itens {
    scroll-snap-type: none;
  }

  .carrousel-header-buttons .carousel-arrows-navigators {
    display: flex;
  }
}

@media (min-width: 1200px) {
  .carousel-gradient-right {
    display: block;
  }
}

@media (min-width: 1400px) {
  .carousel-gradient-right {
    right: 0;
  }
}

@media (min-width: 1600px) {
  .carousel-gradient-left {
    display: block;
  }

  .carousel-gradient-left {
    right: 0;
    width: 600px;
  }

  .carousel-gradient-right {
    right: 0;
    width: 600px;
  }
}
