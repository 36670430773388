.car-content-resources {
  list-style: none;
  margin: 40px 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.car-content-resources li {
  width: 100%;
  padding-left: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #eaf0ef;
}

.car-content-resources li svg {
  margin-right: 10px;
}

@media (min-width: 992px) {
  .car-content-resources li {
    width: 45%;
  }
}
