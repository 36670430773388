.dashboard-sidebar {
  display: none;
}


@media (min-width: 992px) {

  .dashboard-sidebar {
    width: 24%;
    display: flex;
    flex-direction: column;
  }

  .dashboard-sidebar-promo {
    margin-top: auto;
    justify-self: flex-end;
  }

  .dashboard-sidebar-promo .page-container-promo-img-mobile_size {
    margin-top: 40px;
  }

  .dashboard-sidebar h3 {
    font-size: 2.5em;
    font-family: "Exo 2";
    font-style: italic;
    color: #1c4d1f;
    font-weight: 800;
  }

  .dashboard-sidebar .dashboard-menu-sidebar {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 1.2em;
  }

  .dashboard-sidebar .dashboard-menu-sidebar li a {
    padding: 5px 0;
    margin: 5px 0;
    display: block;
    color: #77919c;
  }

}
