.account-content span {
  display: block;
  margin: 30px 0;
  color: #77919c;
}

.account-content span:last-child {
  margin-bottom: 0;
}

.account-edit-link {
  text-decoration: underline;
  color: #77919c;
  font-weight: bold;
}

.account-user-data {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.account-user-data span {
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid #eaf0ef;
}

@media (min-width: 992px) {
  .account-user-data span {
    width: 45%;
  }

  .account-content {
    margin: 48px 0;
  }

  .account-content .title-page {
    display: none;
  }

}
