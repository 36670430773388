.faq-list {
  display: flex;
  flex-direction: column;
}

.faq-item {
  border-top: 1px solid #eaf0ef;
  text-align: center;
  font-size: 1.2em;
  scroll-margin-top: 70px;
}

.faq-item:first-child {
  border-top: none;
}

a.faq-item-title {
  display: block;
  padding: 20px;
  cursor: pointer;
  color: #2a3b41;
}

.faq-item-content {
  display: none;
}

.faq-item.show {
  border: 1px solid #eaf0ef;
  border-radius: 10px;
  margin-bottom: 10px;
}

.faq-item.show .faq-item-content {
  display: block;
  padding: 10px 20px 20px 20px;
  color: #666;
}

.faq-button {
  width: 100%;
  text-align: center;
  margin: 20px 0;
}

.faq-button a {
  color: #1c4d1f;
  text-decoration: underline;
  font-size: 1.3em;
  font-family: "Exo 2";
  font-weight: 700;
}

.faq-search {
  position: relative;
  margin-bottom: 20px;
}

.faq-search .icon {
  position: absolute;
  right: 15px;
  top: 15px;
}

@media (min-width: 992px) {
  .faq-item {
    text-align: start;
  }
}
