.font-emphasis {
  font-family: 'Exo 2';
  font-style: italic;
}

.font-exo {
  font-family: 'Exo 2';
}

.font-size-small {
  font-size: 14px;
  line-height: 28px;
}

.font-size-normal {
  font-size: 16px;
  line-height: 24px;
}

.font-size-medium {
  font-size: 24px;
  line-height: 32px;
}

.font-size-big {
  font-size: 30px;
  line-height: 38px;
}

.font-size-bigger {
  font-size: 38px;
  line-height: 46px;
}
