.loading-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #edf0f171;
  border-radius: 10px;
  color: #517280;
  margin: 20px 0;
}
