.renting-steps-list {
  width: 100%;
  padding: 20px 0;
}

.steps {
  list-style: none;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.step {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1c4d1f;
}

.step-icon {
  line-height: 0;
  font-size: 0.7em;
  padding: 3px;
  margin-right: 3px;
  border: 1px solid #1c4d1f;
  border-radius: 100px;
}

.step-number {
  line-height: 1em;
  font-size: 0.95em;
  padding: 2px 5px;
  margin-right: 3px;
  border: 1px solid #1c4d1f;
  border-radius: 100px;
}

.step.default .step-number,
.step.default .step-icon.default {
  color: #fff;
  background-color: #1c4d1f;
}

.step-label {
  font-size: 0.9em;
  margin-top: 3px;
  color: #1c4d1f;
}

.steps-separator {
  flex-grow: 1;
  margin: 5px;
  border-bottom: 1px solid #1c4d1f;
}

.step-booking {
  cursor: pointer;
}


@media (min-width: 992px) {
  .steps-separator {
    margin: 15px 24px;
  }
}
