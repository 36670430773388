.carousel-full-page .carousel-item {
  width: 70%;
  height: 100%;
  flex-shrink: 0;
  scroll-snap-align: start;
  padding: 15px;
}

.carousel-full-page .carousel .null-item {
  display: none;
}

.carousel-full-page {
  position: relative;
}

@media (min-width: 768px) {
  .carousel-full-page .carousel .null-item {
    display: block;
    width: calc((100vw - 540px)/2);
  }
}

@media (min-width: 992px) {
  .carousel-full-page .carousel .null-item {
    display: block;
    width: calc((100vw - 960px)/2);
  }

  .carousel-full-page .carousel-item {
    width: calc(992px / 3);
  }
}

@media (min-width: 1200px) {
  .carousel-full-page .carousel .null-item {
    width: calc((100vw - 1140px)/2);
  }

  .carousel-full-page .carousel-item {
    width: calc(1140px / 3);
  }
}

@media (min-width: 1400px) {


  .carousel-full-page .carousel .null-item {
    width: calc((100vw - 1320px)/2);
  }

  .carousel-full-page .carousel-item {
    width: calc(1320px / 3);
  }
}
