.car-group {
  margin-bottom: 30px;
}

.car-group .card-img {
  height: 300px;
}

.car-group.unavalable .carousel-card-images img {
  filter: grayscale();
  opacity: 0.5;
}

.car-group.car-group-empty .card-content {
  background-color: #edf0f1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.car-group .card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 400px;
  height: 100%;
}

.car-group h3 {
  font-size: 20px;
  line-height: 28px;
  margin: 0 0 10px 0;
  color: #2a3b41;
}

.car-group .info-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.car-group .price {
  font-family: 'Exo 2';
  font-size: 2em;
  line-height: 46px;
  font-weight: 900;
  color: #1c4d1f;
  display: flex;
  align-items: flex-start;
  line-height: 1em;
  white-space: nowrap;
}

.car-group .price span {
  display: inline-block;
  margin-left: 3px;
  font-size: 0.4em;
}

.car-group .price .text {
  font-weight: normal;
  color: #517280;
  font-size: 0.4em;
  margin-left: 5px;
  margin-top: 2px;
}

.car-group .btn {
  font-weight: bold;
  color: #fff;
  font-size: 16px;
  background-color: #f08220;
  border: none;
}

.car-group-footer {
  color: #517280;
}

.car-group-footer .collapse-title {
  font-size: 16px;
}

.car-group-footer .collapse-content {
  padding: 5px;
}

.car-group .features-tags {
  margin-bottom: 20px;
}

.car-group-queue-modal h2 {
  margin-top: 0 !important;
}

.car-group-queue-modal .btn-group {
  padding-top: 20px;
  width: 100%;
}

.car-group-queue-modal .btn-group .btn {
  margin-right: 10px;
}


@media (min-width: 992px) {
  .car-group .price {
    font-size: 2.3em;
  }
}
