.page-container-promo {
  margin: 40px 0;
}

.page-container-promo .page-container-promo {
  width: 100%;
}

.page-container-promo .page-container-promo-img {
  width: 100%;
  display: none;
}

.page-container-promo .page-container-promo-img-mobile {
  width: 100%;
}

.page-container-promo-img-mobile_size {
  width: 100%;
}

@media (min-width:992px) {
  .page-container-promo {
    margin: 90px 0;
  }

  .page-container-promo .page-container-promo-img {
    width: 100%;
    display: block;
  }

  .page-container-promo .page-container-promo-img-mobile {
    display: none;
  }
}
