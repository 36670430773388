.select-login-type {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 5px;
}

.select-login-type div {
  position:relative;
  display: flex;
  align-items: center;
  /* overflow: hidden; */
}

.select-login-type div input {
  top: -100%;
  left: -100%;
  position: absolute;
  visibility: hidden;
}

.select-login-type div label {
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.select-login-type div label svg {
  font-size: large;
}
