.modal-user-activation {
  margin-top: 10vh;
  text-align: center;
}

.modal-user-activation h2 {
  margin: 0 0 24px 0;
  width: 100%;
}

.modal-user-activation .field{
  width: 100%;
}

.modal-user-activation .field input {
  text-align: center;
}

@media (min-width:992px) {

  .modal-user-activation {
    width: 24vw;
    padding: 36px;
    margin-top: 20vh;
  }

}
