.field {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  margin: 5px 0;
}


.field label {
  color: #517280;
  margin-bottom: 5px;
}

.field .field-invalid-message {
  width: 100%;
  margin-top: 5px;
}

input,
textarea,
.select-styled,
.input-file-box {
  width: 100%;
  display: block;
  padding: 12px 15px;
  border-radius: 5px;
  border: none;
  background-color: #eaf0ef;
  color: #517280;
  font-size: 1em !important;
  border-width: 2px;
  border-style: solid;
  border-color: #eaf0ef;
}

input:focus-visible,
textarea:focus-visible,
select:focus-visible,
.input-file-box:focus-visible {
  outline: 0;
}

input:hover,
textarea:hover,
select:hover,
.checkbox-styled:hover,
.select-styled:hover,
.input-file-box:hover {
  border-color: #1c4d1f;
}

input:disabled:hover,
textarea:disabled:hover,
select:disabled:hover,
.checkbox-styled:disabled:hover,
.select-styled:disabled:hover,
.input-file-box:disabled:hover {
  border-color: #eaf0ef;
}

input:focus,
textarea:focus,
select:focus,
.checkbox-styled:focus,
.select-styled:focus,
.select-styled.open,
.input-file-box:focus {
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  border-color: #1c4d1f;
}

input:disabled,
textarea:disabled,
select:disabled,
.input-file-box:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.field.typeHidden {
  padding: 0;
  margin: 0;
}

select {
  cursor: pointer;
}

form button {
  margin: 10px 5px;
  background-color: #f08220;
  border-radius: 5px;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

form button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.form-session {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -5px 20px -5px;
}

.field.invalid input,
.field.invalid textarea,
.field.invalid .select-styled {
  border: 2px solid red
}

.field.invalid .field-checkbox {
  padding: 5px;
  border-radius: 5px;
  border: 2px solid red
}

.checkbox-group .field.invalid {
  padding: 5px;
  border-radius: 5px;
  border: 2px solid red
}

.field .required-indicator {
  color: rgb(248, 96, 96);
  position: absolute;
  top: 18px;
  left: 12px;
}

.checkbox-group .required-indicator {
  top: 3px;
  left: 5px;
}


@media (min-width: 992px) {
  .field .field-invalid-message {
    position: absolute;
    background-color: red;
    color: #fff;
    bottom: 100%;
    padding: 5px;
    font-size: 0.8em;
    border-radius: 10px;
    width: 200px;
    display: none;
  }

  .field.invalid:hover .field-invalid-message {
    display: block;
  }

  .field .field-invalid-message:after {
    content: "";
    position: absolute;
    left: 5%;
    margin-right: -5px;
    top: 120%;
    transform: translateY(-50%);
    border: 5px solid red;
    border-color: red transparent transparent transparent;
  }
}
