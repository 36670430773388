.car-content-description {
  list-style: none;
  margin: 0 0 60px 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.car-content-description li {
  width: 100%;
  padding: 20px;
  margin: 0 0 10px 0;
  border: 1px solid #eaf0ef;
  border-radius: 5px;
  font-size: medium;
  display: flex;
  align-items: center;
}

.car-content-description li .icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.car-content-description li .icon img {
  width: 100%;
  height: 100%;
}

@media (min-width:992px) {
  .car-content-description li {
    width: 45%;
  }
}
