.add-driver-form {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
}

.add-driver-form .field-name,
.add-driver-form .field-cnh,
.add-driver-form .cnh-security-number {
  width: 100%;
}

@media (min-width: 992px) {

  .add-driver-form .field-cnh,
  .add-driver-form .cnh-security-number {
    width: 50%;
  }

}
