.notification {
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #eaf0ef;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.notification:last-child {
  margin-bottom: 0;
}

.notification .notification-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #2a3b41;
  font-weight: 700;
  padding: 15px;
  border-bottom: 1px solid #eaf0ef;
}

.notification .notification-description {
  padding: 15px;
}

.notification .notification-title .read {
  cursor: pointer;
  color: #77919c;
}

.notification .notification-title .read:hover {
  color: #f08220;
}

.notification .notification-description {
  color: #77919c;
}

.notification .notification-description a {
  color: #1c4d1f;
}
