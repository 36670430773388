.main-nav-user > li {
  position: relative;
  padding: 0 10px;
}

.main-nav-user-drop {
  position: absolute;
  top: 185%;
  right: 0;
  width: 150%;
  background-color: #fff;
  list-style: none;
  padding: 10px;
  margin: 0;
  box-shadow: 0px 5px 15px rgb(119,145,156, 0.28);
}