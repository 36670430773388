
.aboutus-services {
  margin: 100px 0;
}

.aboutus-services-list {
  list-style: none;
  margin: 40px 0;
  padding: 0;
  display: flex;
  gap: 20px;
  flex-direction: column;
  width: 100%;
}

.aboutus-service {
  font-size: 1.1em;
  color: #517280;
}

.aboutus-service .card {
  height: 100%;
}

.aboutus-service .card-content {
  padding: 35px;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.aboutus-services h2 {
  font-size: 2.5em;
  font-family: "Exo 2";
  font-style: italic;
  font-weight: 900;
  margin: 0;
}

.aboutus-service h3 {
  font-family: "Exo 2";
  font-size: 2.5em;
  line-height: 1.1em;
  font-weight: 500;
  margin: 0 0 20px 0;
  font-style: italic;
  color: #2a3b41;
}

.aboutus-service-link {
  display: block;
  justify-self: flex-end;
}

.aboutus-service-abstracts {
  margin-bottom: 20px;
}


@media (min-width: 992px) {

  .aboutus-services-list {
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    gap: 20px;
  }

  .aboutus-service {
    width: calc((100% / 3) - 16px)
  }

}
