.checkbox-group{
  margin: 5px 0;
}

.checkbox-group .field {
  flex-direction: column;
  align-items: flex-start;
}

.checkbox-group .field > label{
  padding: 5px;
  margin: 5px 0;
}

.checkbox-group .checkbox-group-fields {
  width: 100%;
  display: flex;
}
