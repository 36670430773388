.car-sections {
  width: 100%;
}

.car-price {
  font-weight: 800;
  margin: 0 0 40px 0;
}

.car-contact-form h3,
.car-contacts h3 {
  margin: 0;
}

.car-contact-form {
  margin-bottom: 40px;
}

.car-contacts {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.car-contacts h3,
.car-contacts .phone,
.car-contacts .whatsapp-button {
  margin-bottom: 10px;
}

.car-contacts .whatsapp-button {
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width:992px) {

  .car-sections {
    display: flex;
    flex-direction: row;
    gap: 24px;
    margin-bottom: 60px;
  }

  .car-sections .car-content {
    width: 66%;
    height: 100%;
  }

  .car-sections .car-sidebar {
    width: 34%;
    height: 100%;
    z-index: 100;
    margin-top: -200px;
  }
}
