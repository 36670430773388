.page-title {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-title h2 {
  font-size: 30px;
  font-weight: bold;
  border-bottom: 3px solid #f5821f;
}
