.tabs {
  width: 100%;
}

.tabs .tabs-nav {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.tabs .tabs-nav button {
  border: solid 1px #ccc;
  border-radius: 5px 5px 0 0;
  padding: 10px 20px 10px 20px !important;
  margin: 0 3px -1px 0;
  background-color: #eee;
  text-decoration: none;
}

.tabs .tabs-nav button.active {
  border-bottom: none;
  background-color: #fff;
}

.tabs .tabs-content {
  padding: 20px;
  border-top: solid 1px #ccc;
  border-radius: 0px;
}
