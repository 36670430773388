.modal-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99998;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: scroll;
}

.modal {
  position: relative;
  margin: 10vh 0;
  width: 80vw;
  min-height: 300px;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.modal.modal-transparent {
  background-color: transparent;
  border-radius: none;
  padding: 0;
  box-shadow: none;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  color: #517280;
  cursor: pointer;
  z-index: 9;
}

.modal.modal-transparent .modal-close {
  color: #fff;
}

.modal .modal-content {
  padding: 5px 20px 0 0;
}
