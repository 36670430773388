.password-field {
  position: relative;
}

.password-field .hide-show {
  position: absolute;
  right: 20px;
  top: 17px;
  font-size: 16px;
  color: #517280;
  cursor: pointer;
}

.password-field input[type="password"] {
  padding: 3px 15px ;
  font-size: 30px !important;
  font-weight: 900;
  letter-spacing: 7px;
}

.password-field input[type="text"] {
  font-size: 1em !important;
  font-weight: normal;
  padding: 12px 15px ;
}

.password-field input::placeholder {
  font-size: 14px;
  font-weight: normal;
  letter-spacing: normal;
}
