.cnh-register-number {
  width: 100%;
  position: relative
}

.cnh-register-number .field {
  width: 100% !important;
}

.cnh-register-number > span {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 16px;
  color: #517280;
  cursor: pointer;
}

@media (min-width: 992px) {

  .modal-cnh-register-number {
    width: 30vw;
    text-align: center;
  }

  .modal-cnh-register-number-content {
    margin-bottom: 20px;
  }

  .modal-cnh-register-number img {
    width: 100%;
  }

}
