.text-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #EAF0EF;
  padding: 40px;
  gap: 20px;
  border-radius: 10px;
  text-align: left;
  margin: 60px 0;
}

.title-section h2 {
  font-size: 2.5em;
  font-weight: 800;
  margin: 0;
  line-height: 1.3;
}

.text-section {
  font-size: 1.1em;
  font-weight: 600;
}

.text-section p {
  line-height: 1.3em;
  font-size: 1.3em;
}

.text-section button{
  margin-top: 20px;
}

@media (min-width: 992px) {
  .text-box {
    flex-direction: row;
    padding: 40px 50px;
    gap: 60px;
  }
}
