.rent-info {
  font-size: 1.2em;
}

.rent-info-location {
  display: block;
  width: 100%;
  margin-bottom: 0px;
}

.rent-info-date {
  display: block;
  width: 100%;
}

.rent-info .rent-info-extra {
  color: #517280;
}

.rent-info .rent-info-extra h3 {
  color: #2a3b41;
  margin: 0;
}

.rent-info .rent-info-extra .more {
  color: #f08220;
}
