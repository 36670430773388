.faq-block {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 60px 0;
}

.faq-block-info {
  width: 100%;
}

.faq-block-info h2 {
  font-family: "Exo 2";
  font-style: italic;
  font-weight: 900;
  font-size: 2.5em;
  margin: 10px 0;
  text-align: center;
}

.faq-block-questions {
  width: 100%;
}

.faq-block .contact-time {
  display: none;
}

.faq-block .faq-block-info-contact {
  display: none;
}

@media (min-width: 992px) {

  .faq-block-info h2 {
    text-align: start;
  }

  .faq-block-info {
    width: 35%;
    padding-right: 100px;
  }

  .faq-block-questions {
    width: 65%;
  }

  .faq-block-info-contact {
    font-size: 1em;
    margin-bottom: 20px;
  }

  .faq-block-info-contact .btn {
    font-size: 1em;
  }

  .faq-block .faq-button {
    text-align: start;
    padding: 0 20px;
  }

  .faq-block .faq-block-info-contact {
    display: block;
  }

  .faq-block .contact-time {
    display: block;
  }
}
