.car-shop-content {
  max-width: 800px;
  margin: 80px auto 0 auto;
  text-align: justify;
}

.car-shop-content h2 {
  width: 100%;
  text-align: center;
  margin: 0 0 40px 0;
  font-size: 3em;
  font-family: "Exo 2";
  font-style: italic;
  font-weight: 900;
}
