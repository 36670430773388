.home-terceirizacao-info-content {
  display: flex;
  gap: 50px;
  padding: 40px 0 60px;
  margin-bottom: 60px;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: column;
}

.home-terceirizacao-info-content p {
  color: #517280;
}

.home-terceirizacao-info-content > div:first-child {
  width: 100%;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.home-terceirizacao-info-content > div:first-child > .terceirizacao-info-saiba-mais {
  display: none;
}

.home-terceirizacao-info-content .terceirizacao-info-saiba-mais {
  font-size: medium;
  font-weight: bold;
}

.home-terceirizacao-info-content > div:first-child > h2,
.home-terceirizacao-info-content > div:first-child > p {
  margin: 0 0 20px;
}

.home-terceirizacao-info-content > div:first-child > h2 {
  font-size: 3em;
  font-weight: 900;
  line-height: 1.1em;
}

.home-terceirizacao-info-content > div:last-child {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap:20px;
  flex-direction: column;
}

.home-terceirizacao-info-content > div:last-child > .terceirizacao-info-saiba-mais {
  margin-top: 20px;
}

.home-terceirizacao-info-content div:last-child > div {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.home-terceirizacao-info-content div:last-child > div > svg {
  text-align: left;
  max-width: 25%;
  font-size: 2em;
}

.home-terceirizacao-info-content div:last-child > div > div {
  flex-direction: column;
  width: 75%;
}

.home-terceirizacao-info-content div:last-child > div > div h3,
.home-terceirizacao-info-content div:last-child > div > div p {
  width: 100%;
  margin: 0 0 5px;
}

.home-terceirizacao-info-content div:last-child > div > div h3 {
  line-height: 1.15em;
  font-weight: 800;
}

@media (min-width:992px) {
  .home-terceirizacao-info-content {
    /* padding: 40px 0 60px; */
    /* margin-bottom: 60px; */
    /* justify-content: space-between; */
    /* align-items: stretch; */
    flex-direction: row;
  }

  .home-terceirizacao-info-content > div:first-child {
    width: calc(100% / 3);
    padding: 0 10px 0 0;
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: space-between; */
    /* align-items: flex-start; */
    align-items: flex-start;
    text-align: left;
  }

  .home-terceirizacao-info-content > div:first-child > .terceirizacao-info-saiba-mais {
    display: block;
  }

  .home-terceirizacao-info-content > div:last-child {
    width: calc((100% / 3)*2);
    /* display: flex; */
    /* flex-wrap: wrap; */
    /* justify-content: space-between; */
    align-items: flex-start;
    /* gap:20px; */
    flex-direction: row;
  }

  .home-terceirizacao-info-content > div:last-child > .terceirizacao-info-saiba-mais {
    display: none;
  }

  .home-terceirizacao-info-content div:last-child > div {
    width: 47%;
    /* display: flex; */
    /* justify-content: space-between; */
    /* align-items: flex-start; */
  }
}

