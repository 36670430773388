.car-list {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2%;
}

.car-list .car-card {
  width: 100%;
  margin-bottom: 30px;
}

@media (min-width:992px) {
  .car-list .car-card {
    width: 32%;
  }
}
