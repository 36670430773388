.main-footer {
  background-color: #fff;
}

.main-footer-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px 0;
  padding-bottom: 20px;
}

.footer-column {
  width: 100%;
}

.main-footer-content h2 {
  font-size: 2.4em;
  font-weight: 700;
  font-family: 'Exo 2';
  font-style: italic;
  margin-top: 0;
}

.main-footer-content .phone{
  font-size: 2.5em;
  font-weight: 500;
  font-family: "Exo 2";
  color: #f08220;
  margin-bottom: 20px;
}

.main-footer-content .hour{
  display: flex;
  flex-direction: column;
  color: #77919c;
  font-size: 1.2em;
  margin-bottom: 40px;
}

.main-footer-content .law-text {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  padding: 30px 0;
  border-top: 1px solid #edf0f1;
  color: #77919c;
}

.main-footer-content .law-text .text {
  width: 90%;
}

.main-footer-content .law-text .logo img{
  width: 80px;
  display: none;
}

.main-footer-content .contact-time {
  margin: 0 0 20px 0;
}

.main-footer-content .whatsapp-button {
  width: 150px;
  display: none;
}


@media (min-width: 992px) {
  .main-footer-content {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .footer-column {
    width: 25%;
  }

  .footer-column:first-child {
    width: 50%;
  }

  .main-footer-content .whatsapp-button {
    display: block;
  }

  .main-footer-content .law-text .logo img{
    display: block;
  }
}
