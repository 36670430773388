.blog-post-single .title-page {
  width: 100%;
  text-align: center;
}

.blog-post-single .blog-post-single-img {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 600px;
  overflow: hidden;
  border-radius: 10px;
  margin: 60px 0;
}

.blog-post-single .blog-post-single-img img {
  width: 100%;
}


.blog-post-single .blog-post-single-content {
  max-width: 800px;
  margin: 100px auto;
  text-align: justify;
}

.blog-post-single-content img {
  max-width: 100%;
  border-radius: 10px;
}

.blog-post-single .blog-post-single-createdat {
  width: 100%;
  text-align: center;
}
