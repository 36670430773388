.booking-card-link {
  color: #517280;
}

.booking-card-renew-link {
  color: #77919c;
  font-size: 0.9em;
}

.booking-card-renew-icon {
  margin: 0 10px;
  cursor: help;
}
