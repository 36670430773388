.renting-button {
  margin-top: 20px;
  background: #f5821f !important;
  border-color: #f5821f !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
}

.renting-button:hover {
  color: #000 !important;
  background: orange !important;
}
