.select-field {
  position: relative;
  width: 100%;
  display: block;
  border-radius: 5px;
  border: none;
  background-color: #eaf0ef;
  color: #517280;
}

.select-field:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 20px;
  width: 0;
  height: 0;
  margin-top: -2px;
  border-top: 5px solid #aaa;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}

.select-styled {
  position: relative;
  cursor: pointer;
  padding: 0;
}

.select-styled-options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 999;
  border: 1px solid #eaf0ef;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  display: none;
  margin-top: 5px;
}

.select-styled-options.open {
  display: block;
}

.options-list {
  width: 100%;
  max-height: 250px;
  overflow-y: auto;
}

.select-search {
  width: 100%;
  padding: 10px;
  background-color: #fff;
}

.select-search input {
  background-color: #fff;
  margin-bottom: 10px;
}

.select-styled-options input:hover,
.select-styled-options input:focus {
  border-color: #eaf0ef;
  box-shadow: none;
}

.select-option {
  display: block;
  width: 100%;
  padding: 10px 10px;
  cursor: pointer;
  border-top: 1px solid #eee;
}

.select-styled-selected {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 9px 15px;
  min-height: 40px;
}

.select-styled-selected.select-placeholder {
  color: #666
}

.field.invalid .select-search input {
  border-color: #eaf0ef !important;
}

.select-styled-selected,
.select-option {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

.select-option:hover,
.select-option:focus {
  background-color: #eaf0ef;
  outline: none;
}

.select-field select {
  display: none;
  padding: 12px;
  width: 100%;
  background-color: transparent;

  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}
