.card-form h2 {
  font-size: 2.5em;
  margin: 0.5em 0;
  font-family: 'Exo 2';
  font-style: italic;
}

.payment-card-form .form-session {
  margin: 0;
}

.payment-card-form .form-session .field {
  width: 100%;
}

.payment-card-form .form-session:nth-child(2) .field-cardDate,
.payment-card-form .form-session:nth-child(2) .field-cardCode {
  width: 50% !important;
}

.payment-card-form .use-card-btn {
  margin-left: 5px;
  margin-top: 20px;
}

@media (min-width:992px) {
  .payment-card-form form {
    display: flex;
    gap: 24px;
  }

  .payment-card-form .form-session {
    width: calc(100%/3);
    justify-content: flex-start;
    align-content: flex-start;
  }

  .payment-card-form .form-session:last-child {
    justify-self: flex-end;
    align-self: flex-end;
  }

}
