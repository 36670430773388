.carousel-navigators {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.carousel-navigators .carousel-arrows-navigators {
  display: none;
}

.carousel-page-header .carousel-dot-navigators span {
  background-color: #517280;
}

.carousel-page-header .carousel-dot-navigators span.current {
  background-color: #f08220;
}

@media (min-width: 992px) {
  .carousel-navigators {
    position: relative;
    width: 100%;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
  }

  .carousel-navigators .carousel-arrows-navigators {
    display: block;
    margin-right: 35%;
    font-size: 1.3em;
  }
}
