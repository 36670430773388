.booking-card-options {
  position: absolute;
  display: flex;
  align-items: center;
  right: 10px;
  top: 10px;
  padding-bottom: 5px;
}

.booking-card-options-btn {
  background-color: transparent;
  color: #999;
  width: 50px;
  border: 1px solid #eaf0ef;
}

.booking-card-cancel-btn {
  margin-right: 5px;
  color: #f08220;
  background-color: transparent;
  border: 1px solid #f08220;
}

.booking-card-options-btn:hover,
.booking-card-options-btn.open {

  color: #333;
}

.booking-card-options-menu {
  position: absolute;
  top: 100%;
  right: 0;
  list-style: none;
  padding: 5px 10px;
  margin: 0;
  width: 150px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #eaf0ef;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.booking-card-options-menu li {
  width: 100%;
}

.booking-card-options-menu li a {
  display: block;
  padding: 5px 0;
  color: #1c4d1f;
  font-family: 'Exo 2';
  font-weight: 600;
  border-bottom: 1px solid #eaf0ef;
}

.booking-card-options-menu li:last-child a {
  border: none;
}
