.main-menu {
  position: relative;
  display: flex;
  align-items: center;
  font-family: "Exo 2";
  color: #1c4d1f;
}

.main-menu .main-nav {
  list-style: none;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  padding: 0;
  display: flex;
}

.main-menu .main-nav li {
  margin: 3px;
}

.main-menu .main-nav li a {
  margin: 10px;
  color: #1c4d1f;
  font-weight: 600;
  white-space: nowrap;
}

.main-nav-icon {
  margin: 0 20px;
}

.main-menu .whatsapp-button {
  display: none;
}

.main-menu .whatsapp-button a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.3em;
}

@media (min-width: 1200px) {
  .main-menu .whatsapp-button {
    display: block;
  }
 
  .main-menu .whatsapp-button svg {
    margin: 0 5px 0 0;
  }
  
  .main-menu .whatsapp-button span {
    font-size: 0.8em;
  }
}

@media (min-width: 1400px) {
  .main-menu .whatsapp-button {
    display: block;
  }
}