.booking-sections {
  width: 100%;
}

.booking-page .car-group-details {
  font-size: 1.2em;
  margin-bottom: 40px;
}

.booking-abstract {
  padding: 20px;
  border: solid 1px #eaf0ef;
  border-radius: 10px;
  margin-top: 40px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

@media (min-width:992px) {

  .booking-sections {
    display: flex;
    flex-direction: row;
    gap: 24px;
    margin-bottom: 60px;
  }

  .booking-sections .booking-content {
    width: 66%;
    height: 100%;
  }

  .booking-sections .booking-content .rent-info {
    align-self: flex-end;
  }

  .booking-sections .booking-sidebar {
    width: 34%;
    height: 100%;
    z-index: 100;
    margin-top: -120px;
  }

  .booking-page .booking-abstract {
    font-size: 0.9em;
    margin-bottom: 40px;
    margin-top: 0;
  }

  .booking-sections .booking-abstract .title-page {
    font-size: 2.8em;
    margin: 0 0 10px 0;
  }

  .booking-sections .booking-abstract .checkout-total {
    font-size: 2.3em;
  }

  .booking-sections .rent-info {
    align-self: flex-end;
    justify-self: flex-end;
    margin-bottom: 0;
  }
}
