.modal-payment-message .message-content {
  margin-bottom: 30px;
}

.modal-payment-message h2 {
  margin-top: 0;
}

.modal-payment-message {
  margin-top: 7vh;
}

@media (min-width:992px) {
  .modal-payment-message {
    width: 30vw;
  }
}
