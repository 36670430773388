.booking-list {
  margin: 60px 0;
}

.session-title {
  font-family: 'Exo 2';
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  color: #517280;
}

.booking-list-form {
  padding: 30px 25px;
  margin-bottom: 40px;
  border-radius: 10px;
  border: 1px solid #eaf0ef;
}

.booking-list-form .form-secction {
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
}

.booking-list-form .field-code {
  width: 40%;
}

.booking-list-form .field-grupo {
  width: 60%;
}

.booking-list-form .form-row:last-child {
  margin-bottom: 0;
}

.dashboard-content .title-page {
  color: #1c4d1f;
}

.empty-booking-list {
  width: 100%;
  border: 1px solid #eaf0ef;
  border-radius: 10px;
  padding: 20px;
  color: #77919c;
  text-align: center;
}

.dashboard-content .booking .card-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.dashboard-content .client-info-bar {
  justify-content: flex-start;
}

.dashboard-content .client-info-bar span {
  margin: 0 10px;
}

.dashboard-content .booking .card-content .booking-secction:nth-child(1) {
  width: 100%;
}

.dashboard-content .booking .card-content .booking-secction:nth-child(2) {
  width: 100%;
  border-bottom: 1px solid #eaf0ef;
  margin-bottom: 20px;
}

.dashboard-content .booking .card-content .booking-secction:nth-child(3) {
  width: 50%;
}

.dashboard-content .booking .card-content .booking-secction:nth-child(4) {
  width: 50%;
}

@media (min-width: 992px) {

  .dashboard-content .title-page {
    color: #517280;
  }

  .dashboard-content .booking .card {
    display: flex;
    flex-direction: row;
  }

  .dashboard-content .booking .card-content {
    flex-wrap: nowrap;
  }

  .dashboard-content .booking .card .card-img {
    width: 20%;
    min-height: 100px;
  }

  .dashboard-content .booking .card-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
  }

  .dashboard-content .booking .card-content .booking-secction:nth-child(1) {
    width: 30%;
  }

  .dashboard-content .booking .card-content .booking-secction:nth-child(2) {
    width: 30%;
    border: none;
    margin-bottom: 0;
  }

  .dashboard-content .booking .card-content .booking-secction:nth-child(3) {
    width: 30%;
  }

  .dashboard-content .booking .card-content .booking-secction:last-child {
    width: 30%;
  }

  .dashboard-content .booking .booking-group-name {
    margin: 0 0 10px 0;
    font-size: 1.5em;
  }

  .dashboard-content .booking-list-form {
    display: flex;
    border: none;
    padding: 0;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
  }

  .dashboard-content .booking-list-form .form-secction {
    width: 50%;
    margin: 0;
  }
}
