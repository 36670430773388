.account-edit-content .field,
.account-edit-content .cnh-security-number {
  width: 100%;
}

.account-edit-content .form-edit-person {
  padding-bottom: 60px;
  margin-bottom: 40px;
  border-bottom: solid #eee 1px
}

.account-edit-content .form-edit-password {
  margin-bottom: 100px;
}

@media (min-width:992px) {
  .account-edit-content .field,
  .account-edit-content .cnh-security-number {
    width: 33%;
  }
}
