.top-bar {
  width: 100vw;
  padding: 10px 0;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0px 5px 15px rgb(119, 145, 156, 0.28);
  z-index: 99996;
}

.top-bar .logo {
  width: 120px;
}

.top-bar .logo img {
  width: 80%;
}

.top-bar-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.top-bar-nav-item {
  border: none;
  background: transparent;
  padding-left: 25px;
  font-size: 1.8em;
}

.top-bar-nav-item.whatsapplink {
  color: #57c629;
}

.top-bar-nav-item.toggle-main-menu {
  color: #1c4d1f;
}

.tob-bar-nav {
  display: none;
}

.top-bar-nav-mobile {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

body.menu-mobile-open {
  overflow-y: hidden;
  position: fixed;
  z-index: 99999;
}


@media (min-width:992px) {
  .tob-bar-nav {
    display: block;
  }

  .tob-bar-nav-mobile {
    display: none;
  }
}