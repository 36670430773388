.faq-page-list {
  width: 100%;
  margin-bottom: 64px;
}

.faq-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.faq-form .field-query {
  width: 80%;
}

.faq-form .btn {
  width: 20%;
}

@media (min-width: 992px) {

  .faq-page-list .faq-list {
    flex-flow: row wrap;
    align-content: space-between;
    gap: 24px;
  }

  .faq-page-list .faq-list-col {
    width: calc((100% / 2) - 16px);
  }

  .faq-page-list .faq-item {
    width: 100%;
  }

  .faq-page-list .faq-button a {
    display: none;
  }

  .faq-form .field-query {
    width: 95%;
  }

  .faq-form .btn {
    width: 5%;
  }

}
