.account-create-background {
  width: 100%;
  background-size: 0%;
  background-position: 0%;
}

.account-create-box {
  width: 100%;
}

.account-create-box .field {
  width: 100%;
}

.swith_to_login {
  width: 100%;
  text-align: center;
  margin: 10px 0;
  font-size: 18px;
}

.account-create-box .password-rules {
  font-size: 0.8em;
  padding: 3px 20px 20px;
}

.account-create-pjlink {
  display: inline-block;
  padding: 0;
  margin-bottom: 20px;
  color: #517280;
}

@media (min-width:992px) {

  .account-create-background {
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
  }

  .account-create-background .container {
    display: flex;
    justify-content: flex-end;
  }

  .account-create-box {
    width: 38%;
    padding: 48px;
    background: #fff;
    border-radius: 10px;
    margin: 100px 64px;
  }

}
