.sub-title {
  margin: 5px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.sub-title h3 {
  font-size: 20px;
  font-weight: bold;
  border-bottom: 3px solid #f5821f;
}

.sub-title.invert h3 {
  color: #fff;
}
