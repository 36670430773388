.notifications-wrap {
  position: relative;
  display: inline-block;
}

.main-nav-notifications {
  position: relative;
}

.main-nav-notifications .has-notification {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border-radius: 100px;
  background-color: #f08220;
  font-size: 0.7em;
  color: #fff;
  top: 0;
  right: -10px;
}

.notifications-dropdown {
  display: none;
  width: 400px;
  min-height: 100px;
  background-color: #fff;
  position: absolute;
  top: 200%;
  right: -200%;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.notifications-dropdown.open {
  display: block;
}

.notifications-dropdown .notifications-title {
  color: #1c4d1f;
  font-weight: bold;
  font-size: large;
  padding-bottom: 10px;
  border-bottom: 1px solid #eaf0ef;
  background-color: #fff;
  padding: 20px;
}

.notifications-dropdown .notifications-scroll {
  overflow-y: auto;
  max-height: 60vh;
  padding: 20px;
}

.notifications-dropdown .notifications-empty {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #77919c;
}