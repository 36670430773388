.car-shop-filters {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  margin: 10px 0 60px 0;
  padding: 20px;
  border: 1px solid #eee;
  border-radius: 10px;
}

.car-shop-filters .field-fuel,
.car-shop-filters .field-color,
.car-shop-filters .field-features,
.car-shop-filters .field-years {
  width: 100%;
}

@media (min-width: 922px) {

  .car-shop-filters .field-fuel,
  .car-shop-filters .field-color,
  .car-shop-filters .field-features,
  .car-shop-filters .field-years {
    width: 25%;
  }

}
