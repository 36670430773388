.checkbox-stld {
  position:relative;
  display: flex;
  align-items: center;
}

.checkbox-stld input {
  top: -100%;
  left: -100%;
  position: absolute;
  visibility: hidden;
}

.checkbox-stld label {
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.checkbox-stld label .fa-check-square {
  display: none;
}

.checkbox-stld input:checked + label .fa-square {
  display: none;
}

.checkbox-stld input:checked + label .fa-check-square{
  display: inline-block;
}

.checkbox-stld label svg {
  font-size: large;
}
