.carousel-car-brand {
  margin-top: -65px;
  margin-bottom: 20px;
}

.carousel-car-brand .carousel-header {
  margin-bottom: 10px;
  min-height: 30px;
}

.carousel-car-brand .carousel-item {
  width: 40%;
  padding: 10px;
  cursor: pointer;
}

.carousel-car-brand .carousel-item.selected .card {
  border: 2px solid #1c4d1f
}

.carousel-car-brand .carousel-item .card-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-car-brand .carousel-item .card-content img {
  width: 70%;
}

@media (min-width: 768px) {
  .carousel-car-brand .carousel-item {
    width: calc(768px / 4);
  }
}

@media (min-width: 992px) {
  .carousel-car-brand .carousel-item {
    width: calc(992px / 8);
  }
}

@media (min-width: 1200px) {
  .carousel-car-brand .carousel-item {
    width: calc(1140px / 8);
  }
}

@media (min-width: 1400px) {
  .carousel-car-brand .carousel-item {
    width: calc(1320px / 8);
  }
}
