.booking-list-itens .card-content {
  padding: 0;
}

.booking-card {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 120%;
  align-items: stretch;
}

.booking {
  margin-bottom: 30px;
  position: relative;
}

.booking h3 {
  font-size: 20px;
  line-height: 28px;
  margin-top: 5px;
}

.booking .booking-code {
  display: inline-block;
  color: #517280;
  margin-top: 20px;
  font-weight: 500;
}

.booking-date-time {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.booking-date-time span {
  color: #77919c;
  margin-bottom: 5px;
}

.booking-detail {
  display: flex;
  align-items: center;
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid #eaf0ef;
  justify-content: space-between;
}

.booking-price {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: flex-start;
  color: #1c4d1f;
  font-family: 'Exo 2';
  font-size: 2em;
  font-weight: 900;
  white-space: nowrap;
}

.booking-price small {
  margin-top: 5px;
  font-size: 0.5em;
}

.booking-price span {
  margin-top: 5px;
  font-size: 0.6em;
}

.booking-img-secction {
  width: 100%;
  height: 250px;
  background-color: #e9f0f0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.booking-img-secction img {
  width: 100%;
}

.booking-info-secction {
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid #eaf0ef;
}

.booking-group-name {
  color: #1c4d1f;
}

.booking-status-secction {
  width: 50%;
  padding: 20px 0 20px 20px;
  align-self: center;
}

.booking-price-secction {
  width: 50%;
  padding: 20px 20px 20px 0;
  align-self: center;
}

.booking .dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
}

.booking .dialog .dialog-content {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}


@media (min-width: 992px) {
  .booking-img-secction {
    width: 25%;
    height: auto;
  }

  .booking-info-secction {
    width: 35%;
    border-bottom: none;
  }

  .booking-status-secction {
    width: 20%;
    padding: 20px;
    display: flex;
  }

  .booking-price-secction {
    width: 20%;
    padding: 20px;
  }

  .booking .booking-code {
    margin-top: 0;
  }

  .booking-date-time {
    margin-bottom: 0;
  }
}
