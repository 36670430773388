.fixed-top-bar {
  background-color: #fff;
  width: 100%;
  position: fixed;
  top: 69px;
  left: 0;
  z-index: 99997;
}

.fixed-top-bar.transparent {
  background-color: transparent;
}

